<template>
    <!-- form -->
    <form class="validate-form" @submit.prevent="updateAssessments">
    <!-- filter assessments -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Assessments</h4>
            <base-card-drop-down>
                <template #title>
                    Options
                </template>
                <template #default>

                    <a class="dropdown-item" href="#" @click="setActiveAssessmentForm('create_assessment')">
                        <i class="bi bs-icon-tiny"
                           :class="activeAssessmentForm == 'create_assessment'?'bi-check-circle':'bi-circle' " ></i>
                        Create new assessment
                    </a>
                    <a class="dropdown-item" href="#" @click="setActiveAssessmentForm('existing_assessment')">
                        <i class="bi bs-icon-tiny"
                           :class="activeAssessmentForm == 'existing_assessment'?'bi-check-circle':'bi-circle' " ></i>
                        Selecting existing assessment
                    </a>
                    <a class="dropdown-item" href="#" @click="setActiveAssessmentForm('existing_assessment_config')" v-if="false">
                        <i class="bi bs-icon-tiny"
                           :class="activeAssessmentForm == 'existing_assessment_config'?'bi-check-circle':'bi-circle' " ></i>
                        Select existing assessment config
                    </a>

                </template>
            </base-card-drop-down>
        </template>
        <template #default>

                <div class="row" v-if="activeAssessmentForm == 'existing_assessment_config'">
                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label"
                                   :class="oldAssessment.assessment.msg?'text-danger':''"
                                   for="existingAssessments">
                                Existing filter assessments*
                                <span v-if="oldAssessment.assessment.msg" > | {{ oldAssessment.assessment.msg }}</span>
                            </label>
                            <select class="form-select" id="existingAssessments"
                                    @change="validate.validateFormData(oldAssessment,'assessment',false)"
                                    v-model="oldAssessment.assessments.val"
                            >
                                <option value="">Select existing assessments</option>
                                <option v-for="assessment in compExistingAssessments"
                                        :key="assessment.assessment"
                                        :value="JSON.stringify(assessment)"
                                 >
                                    {{ assessment.assessment }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="activeAssessmentForm == 'existing_assessment'">
                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label"
                                   :class="oldAssessment.assessment.msg?'text-danger':''"
                                   for="existingAssessments">
                                Existing assessments*
                                <span v-if="oldAssessment.assessment.msg" > | {{ oldAssessment.assessment.msg }}</span>
                            </label>
                            <select class="form-select" id="existingAssessments"
                                    @change="validate.validateFormData(oldAssessment,'assessment',false)"
                                    v-model="oldAssessment.assessment.val"
                            >
                                <option value="">Select existing assessments</option>
                                <option v-for="assessment in compExistingAssessments"
                                        :key="assessment.assessment"
                                        :value="JSON.stringify(assessment)"
                                 >
                                    {{ assessment.assessment }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row" >
                        <div class="col-12" v-if="oldAssessment.reqFeedback.msg">

                            <base-list-tag :tagColor="oldAssessment.reqFeedback.val" >{{oldAssessment.reqFeedback.msg}}</base-list-tag>
                        </div>


                        <div class="col-3 col-lg-3 col-sm-12 mb-3" v-if="oldAssessment.showSubmit.val">

                            <base-button
                                btnColor="primary"
                                btnType="outline"
                                btnAction="button"
                                :btnSelect="true"
                                @click="addOldAssessment"
                            >
                                Add assessment
                            </base-button>
                        </div>
                        <base-spinner  v-else></base-spinner>
                    </div>
                </div>
                <!-- end add existing assessment -->

                <!-- add new assessment -->
                <div class="row" v-if="activeAssessmentForm == 'create_assessment'">

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="newAssessment.assessment.msg?'text-danger':''" for="assessment">
                                Assessment name*
                                <span v-if="newAssessment.assessment.msg" > | {{newAssessment.assessment.msg}}</span>
                            </label>
                            <input type="text" class="form-control" @keyup="validate.validateFormData(newAssessment,'assessment',false)"
                                   placeholder="Assessment name" id="assessment" name="assessment"
                                   v-model="newAssessment.assessment.val" />
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-1">
                            <label class="form-label"
                                   :class="newAssessment.assessmentDescription.msg?'text-danger':''"
                                   for="assessmentDescription">
                                Assessment description*
                                <span v-if="newAssessment.assessmentDescription.msg" >
                                    | {{newAssessment.assessmentDescription.msg}}
                                </span>
                            </label>
                            <textarea class="form-control" id="assessmentDescription" rows="4"
                                      @keyup="validate.validateFormData(newAssessment,'assessmentDescription',false)"
                                      v-model="newAssessment.assessmentDescription.val"
                                      placeholder="Brief description about your assessment...">
                            </textarea>
                        </div>
                    </div>

                    <div class="row" >
                        <div class="col-12" v-if="newAssessment.reqFeedback.msg">

                            <base-list-tag :tagColor="newAssessment.reqFeedback.val" >{{newAssessment.reqFeedback.msg}}</base-list-tag>
                        </div>


                        <div class="col-3 col-lg-3 col-sm-12 mb-3" v-if="newAssessment.showSubmit.val">

                            <base-button
                                btnColor="primary"
                                btnType="outline"
                                btnAction="button"
                                :btnSelect="true"
                                @click="addNewAssessment"
                            >
                                Add assessment
                            </base-button>
                        </div>
                        <base-spinner  v-else></base-spinner>
                    </div>

                </div>
            <!-- end add new assessment -->






        </template>
    </base-card-plain>
    <!-- / filter assessments -->

    <base-card-plain>
        <template #header>
            <h4 class="card-title" :class="draftStatus?'text-warning':''">
                Selected Assessments

            </h4>
            <base-card-drop-down>
                <template #title>
                    <span class="text-warning" style="font-size:12px;float:right" v-if="draftStatus">
                        you have unsaved changes*
                    </span>
                </template>
            </base-card-drop-down>
        </template>
      <template #default>
        <div class="row">


        <div class="col-12">
              <draggable
                v-model="fv.assessments.val"
                v-bind="dragOptions"
                @start="drag = true"
                @end="drag = false"
                item-key="order"
                :move="detectListChange"
                >
                <template #item="{ element }">
                  <li class="list-group-item"
                      @dblclick="showAssessmentOptions"
                      :class="element.status == 'draft'?'text-warning':'text-primary' ">
                    <i class="bi bi-three-dots-vertical bs-icon-tiny" ></i>
                    {{ element.assessment }}

                    <span @click= "deleteAssessment(element.assessment)" type="button"
                          class="btn-close bs-icon-tiny text-warning float-end"
                          data-dismiss="alert" aria-label="Close">
                    </span>
                  </li>
                </template>
              </draggable>
        </div>

        <div class="col-12 mt-2" v-if="fv.reqFeedback.msg">

            <base-list-tag :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>

        </div>

        <div class="col-12 pt-2" v-if="fv.showSubmit.status">

            <div class="col-12" v-if="fv.showSubmit.val">
                <base-button btnColor="primary" >Save information</base-button>
            </div>
            <base-spinner  v-else></base-spinner>
        </div>

      </div>
    </template>
  </base-card-plain>






    </form>
    <!--/ form -->
</template>

<script>
import { ref, reactive, computed, inject } from "vue";
import { useStore } from 'vuex';
import draggable from "vuedraggable";
import validators from '@/hooks/validation';

export default{
    props:{
        currJobId:{
            default:"none",
            type: String,
            required: false
        }
    },
    components: {
      draggable
    },
    setup(props){

        // get the axios object
        const axios = inject('axios');  // inject axios

        // set the validators function
        const validate = validators();

        //get the urls
        const coreUrl = inject('coreUrl');

        // set the calue of the request Error
        const reqError = ref("Loading data ....");

        const store = useStore();

        // use the dispatch function to call an action that calls a mutation
        // to set the data of for the job ad
        const oldJobData = ref(store.getters["recruitment/getJobAd"]);

        const lastSavedAsessments = ref(oldJobData.value.assessments)

        // form values to be used in this form
        const oldAssessment = reactive({
            assessment : {status:false, val:"", msg:false, validate:["required"]},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:false, val:true}
        });

        // form values to be used in this form
        const newAssessment = reactive({
            assessment : {status:false, val:"", msg:false, validate:["required","safeSpaceInput"]},
            assessmentDescription : {status:false, val:"", msg:false, validate:["required"]},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:false, val:true}
        });

        const assessmentList = [
              {
                assessment: "Test",
                description: "A written assessment",
                options:{},
                duration:[],
                status:"active"
              },
              {
                assessment: "Interview",
                description: "Interview for determining expertise",
                options:{},
                duration:[],
                status:"active"
              }
          ];

        // form values to be used in this form
        const fv = reactive({
            assessments : {status:false, val:oldJobData.value.assessments?JSON.parse(oldJobData.value.assessments):assessmentList, msg:false, validate:["required"]},
            existingAssessments : {status:false, val:[], msg:false, validate:["required"]},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:false, val:true}
        });

        // when the vue module is created, get the current profile data, using the try catch
        axios.get(
            coreUrl.backendUrl+'get_existing_assessments',
            {withCredentials: true}).then((response) =>{

            // return the data to result and update existing data variable
            const result = response.data.data.assessments;
            fv.existingAssessments.val = result?JSON.parse(result):[];

        }).catch((error)=>{
            let errorMsg = "Can't load location data at this time."
            reqError.value = error.response.data.message.req_msg?errorMsg:errorMsg;
        })

        function addNewAssessment(){

            newAssessment.showSubmit.val = false;

            let duplicateExistingAssessment = fv.existingAssessments.val;
            let duplicateNewAssessment = fv.assessments.val.findIndex(item => item.assessment === newAssessment.assessment.val) < 0 ? true:false;

            if(newAssessment.assessment.status && newAssessment.assessmentDescription.status && duplicateNewAssessment){

                let assessment = newAssessment.assessment.val;
                let assessmentDescription = newAssessment.assessmentDescription.val;
                let status = "draft";

                const newAssessmentData = {
                    assessment:assessment,
                    assessmentDescription,
                    options:{},
                    duration:[],
                    status
                }

                fv.assessments.val.push(newAssessmentData)
                duplicateExistingAssessment?fv.existingAssessments.val.push(newAssessmentData):"";

                fv.showSubmit.status = true;
                newAssessment.showSubmit.val = true;
                newAssessment.reqFeedback.msg = "";
                fv.reqFeedback.msg = "";

                draftStatus.value = true;
            }else{
                newAssessment.showSubmit.val = true;
                newAssessment.reqFeedback.status = true;
                newAssessment.reqFeedback.val = "danger";
                newAssessment.reqFeedback.msg = "Kindly enter the appropriate values correctly";
                newAssessment.assessment.msg = duplicateNewAssessment?"":"You cannot enter a duplicate assessment";
            }
        }

        const compExistingAssessments = computed(function(){
            let existingAssessments = fv.existingAssessments.val.filter(item => item.status !== "draft");
            return existingAssessments;
        })

        function addOldAssessment(){

            oldAssessment.showSubmit.val = false;

            let assessmentSet = JSON.parse(oldAssessment.assessment.val)

            assessmentSet.status = "draft";

            let duplicateOldAssessment = fv.assessments.val.findIndex(item => item.assessment === assessmentSet.assessment) < 0 ? true:false;

            if(oldAssessment.assessment.status && duplicateOldAssessment){

                fv.assessments.val.push(assessmentSet);

                fv.showSubmit.status = true;
                oldAssessment.showSubmit.val = true;
                oldAssessment.reqFeedback.msg = "";
                fv.reqFeedback.msg = "";

                draftStatus.value = true;
            }else{
                oldAssessment.showSubmit.val = true;
                oldAssessment.reqFeedback.status = true;
                oldAssessment.reqFeedback.val = "danger";
                oldAssessment.reqFeedback.msg = "Kindly select a different assessment, or create a new one";
                oldAssessment.assessment.msg = duplicateOldAssessment?"":"You cannot enter a duplicate assessment";
            }
        }


        const draftStatus = ref(false);

        const drag = ref(false);

        const dragOptions = computed(function(){

                  return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                  };
        })

        function detectListChange(){
            fv.showSubmit.status = true;
            fv.showSubmit.val = true;

            for(let i = 0; i < fv.assessments.val.length; i++){
                fv.assessments.val[i]['status'] = "draft"
            }

            draftStatus.value = true;
        }

        function sortAssessmentList() {
          fv.assessments.val = fv.assessments.val.sort((a, b) => a.order - b.order);
        }

        const activeAssessmentForm = ref("create_assessment");

        function setActiveAssessmentForm(assessmentForm){
            activeAssessmentForm.value = assessmentForm;
        }

        function showAssessmentOptions(){
            alert("This function is for updating assessment options. Not yet available");
        }

        function deleteAssessment(assessment){

            fv.assessments.val = fv.assessments.val.filter(item => item.assessment !== assessment);

            fv.existingAssessments.val = fv.existingAssessments.val.filter(function(item){

                if(item.assessment === assessment && item.status === "draft"){
                    return false;
                }

                return true;

            });


            draftStatus.value = lastSavedAsessments.value == JSON.stringify(fv.assessments.val) ? false : true;
        }

        // start create  JD
        async function updateAssessments(){

            sortAssessmentList()

            // start the action of making a submission, and show loader
            fv.showSubmit.val = false;

            let assessments = JSON.stringify(fv.assessments.val);
            let cleanAssessments = assessments.replace(/draft/g,'active');
            let existingAssessments = JSON.stringify(fv.existingAssessments.val);
            let cleanExistingAssessments = existingAssessments.replace(/draft/g,'active');

            // load the form data , and append all the necessary fields
            let formData = new FormData();
            formData.append("fv_assessments", cleanAssessments);
            formData.append("fv_existingAssessments", cleanExistingAssessments);

            // post the data using axios
            try{

                const response = await axios.put(
                    coreUrl.backendUrl+'update_job_ad_assessments/'+props.currJobId,
                    formData,
                    {
                        withCredentials: true,
                        headers:{
                           "Content-Type": "multipart/form-data"
                        }
                    }
                );

                // If successful, get the resppnse data
                const responseData = response.data;


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = responseData.message.req_msg;
                fv.reqFeedback.val = "success";
                fv.showSubmit.status = false;

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;
                fv.assessments.val = JSON.parse(cleanAssessments);
                fv.existingAssessments.val = JSON.parse(cleanExistingAssessments);
                lastSavedAsessments.value = cleanAssessments;

                draftStatus.value = false;


            }catch(error){

                // load the error to the appropriate field
                const fv_msg = error.response.data.message.fv_msg;
                const req_msg = error.response.data.message.req_msg;

                for(const key in fv_msg){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = fv_msg[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = req_msg;
                fv.reqFeedback.val = "danger";

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;

            }

        }
        // end edit JD


        return {
            fv,
            oldAssessment,
            newAssessment,
            addNewAssessment,
            addOldAssessment,
            validate,
            props,
            compExistingAssessments,
            drag,
            dragOptions,
            draftStatus,
            detectListChange,
            sortAssessmentList,
            activeAssessmentForm,
            setActiveAssessmentForm,
            deleteAssessment,
            showAssessmentOptions,
            updateAssessments
        }

    }
}


</script>

<style scoped>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
